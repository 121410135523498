import axios from 'axios';

const state = {

};

const getters = {

};

const actions = {
    async getIndexingApplications(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getIndexingApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getFilteredIndexingApplications(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getFilteredIndexingApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async getStudentIndexingApplication(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.getStudentIndexingApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async ApproveRejectApplication(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.ApproveRejectApplicationUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async approveIndexingBAtchApplications(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.approveIndexingBAtchApplicationsUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async searchIndexingStudent(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.searchIndexingStudentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
    async updateIndexingStudent(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.updateIndexingStudentUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },


    













    
    
    
    
    
    async uploadBatchIndexing(state, data) {// update user additional records
        const authorizationToken = state.rootState.url.authorizationToken;
        const url = state.rootState.url.uploadBatchIndexingUrl;
        const header = {Authorization: `Bearer ${authorizationToken}`};
        let response = await axios.post(url, data, { headers: header});// make api call
        return response.data;
    },
};

const mutations = {

};

export default {
    state,
    getters,
    actions,
    mutations
}