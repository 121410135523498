const rootUrl = 'https://test.api.portal.mdcn.gov.ng';
const remitaAccreditationCallBackUrl = 'https://test.school.portal.mdcn.gov.ng/accreditation-callback';
const remitaAdvisoryCallBackUrl = 'https://test.school.portal.mdcn.gov.ng/advisory-callback';
const portalUrl = 'https://test.portal.mdcn.gov.ng';
// user module Urls 
const getSchoolsUrl = rootUrl+'/get-schools';
const createSignupUrl = rootUrl+'/school-register';
const schoolLoginUrl = rootUrl+'/school-login';
const schoolProfileUrl = rootUrl+'/get-school-profile';
const getSchoolNotificationsUrl = rootUrl+'/get-school-notifications';
const uploadUserPhotoUrl = rootUrl+'/upload-school-photo';
const changePasswordUrl = rootUrl+'/change-school-password';
const deleteSchoolNotificationUrl = rootUrl+'/delete-school-notification';
const updatePasswordUrl = rootUrl+'/update/password';
// Parameter module Urls
const getSiteParameterUrl = rootUrl+'/get-parameter-value';
// Payment Urls
const getAccreditationPaymentUrl = rootUrl+'/school-accreditation-payment';
const saveSchoolTransactionUrl = rootUrl+'/save-school-transaction';
const accreditationRrrStatusUrl = rootUrl+'/school-accreditation-rrr';
const generateRrrUrl = rootUrl+'/generate-assessment-rrr';
const getSchoolPaymentsUrl = rootUrl+'/get-school-payments';
// Application Urls
const getPendingApplicationUrl = rootUrl+'/school-pending-application';
const updateSchoolDataUrl = rootUrl+'/update-school-data';
const deleteSchoolDataUrl = rootUrl+'/delete-school-data';
const submitAccreditationApplicationUrl = rootUrl+'/submit-school-application';
const getSchoolApplicationsUrl = rootUrl+'/get-school-applications';
const getApplicationUrl = rootUrl+'/get-school-application';
const requestVerifyVisitUrl = rootUrl+'/request-verification-visit';
const getSchoolLettersUrl = rootUrl+'/get-school-letters';
const uploadBulkSchoolStaffUrl = rootUrl+'/upload-bulk-school-staff';
// Indexing Urls
const getIndexingApplicationsUrl = rootUrl+'/school/get/indexing/applications';
const getFilteredIndexingApplicationsUrl = rootUrl+'/school/filtered/indexing/applications';
const getStudentIndexingApplicationUrl = rootUrl+'/get/student/application';
const ApproveRejectApplicationUrl = rootUrl+'/school/approve/student/indexing';
const approveIndexingBAtchApplicationsUrl = rootUrl+'/approve/batch/indexing';
const searchIndexingStudentUrl = rootUrl+'/school/search/student';
const updateIndexingStudentUrl = rootUrl+'/school/update/indexing/student';
// other Urls
const exportIndexingListUrl = rootUrl+'/export/student/indexing';



const loadingBladeUrl = rootUrl+'/storage/images/loading.gif';
const logoutUserUrl = rootUrl+'/logout';
const blankImageUrl = rootUrl+'/storage/images/blank-photo.png';
const completeImageUrl = rootUrl+'/storage/images/complete.jpeg';
const resetPasswordUrl = rootUrl+'/reset-school-password';
const printReceiptUrl = rootUrl+'/print-school-receipt';
const certificateImageUrl = rootUrl+'/storage/images/certificate.png';
const perPage = 20;
const recaptchaKey = '6LfIxSUaAAAAAGt9xeD2zcDuZWwcK9lv2Pe6Yu4P';
const websiteUrl = 'https://mdcn.gov.ng';
// Authorization Key
const authorizationToken = '2TcXrFhPN7qIRCEobdeZcYkqZgiSRRmYOwvjce1';
// Remita Urls & Keys 350719346212
const remitaPaymentUrl ='https://login.remita.net/remita/ecomm/init.reg';
const remitaMerchantId = '632028472';
const remitaApiKey = '361049';

const state = {
    getFilteredIndexingApplicationsUrl: getFilteredIndexingApplicationsUrl,
    updatePasswordUrl: updatePasswordUrl,
    uploadBulkSchoolStaffUrl: uploadBulkSchoolStaffUrl,
    getSchoolLettersUrl: getSchoolLettersUrl,
    requestVerifyVisitUrl: requestVerifyVisitUrl,
    updateIndexingStudentUrl: updateIndexingStudentUrl,
    searchIndexingStudentUrl: searchIndexingStudentUrl,
    getIndexingApplicationsUrl: getIndexingApplicationsUrl,
    approveIndexingBAtchApplicationsUrl: approveIndexingBAtchApplicationsUrl,
    ApproveRejectApplicationUrl: ApproveRejectApplicationUrl,
    getStudentIndexingApplicationUrl: getStudentIndexingApplicationUrl,
    getSchoolPaymentsUrl: getSchoolPaymentsUrl,
    getApplicationUrl: getApplicationUrl,
    uploadUserPhotoUrl: uploadUserPhotoUrl,
    deleteSchoolNotificationUrl: deleteSchoolNotificationUrl,
    getSchoolApplicationsUrl: getSchoolApplicationsUrl,
    getSchoolNotificationsUrl: getSchoolNotificationsUrl,
    submitAccreditationApplicationUrl: submitAccreditationApplicationUrl,
    deleteSchoolDataUrl: deleteSchoolDataUrl,
    updateSchoolDataUrl: updateSchoolDataUrl,
    getPendingApplicationUrl: getPendingApplicationUrl,
    generateRrrUrl: generateRrrUrl,
    accreditationRrrStatusUrl: accreditationRrrStatusUrl,
    saveSchoolTransactionUrl: saveSchoolTransactionUrl,
    getAccreditationPaymentUrl: getAccreditationPaymentUrl,
    schoolProfileUrl: schoolProfileUrl,
    schoolLoginUrl: schoolLoginUrl,
    createSignupUrl: createSignupUrl,
    getSchoolsUrl: getSchoolsUrl,
    schoolId: localStorage.getItem('school_id') || null,
    authorizationToken: authorizationToken,
    perPage: perPage,
    remitaApiKey: remitaApiKey,
    remitaPaymentUrl: remitaPaymentUrl,
    remitaMerchantId: remitaMerchantId,
    rootUrl: rootUrl,
    changePasswordUrl: changePasswordUrl,
    getSiteParameterUrl: getSiteParameterUrl,
    portalUrl: portalUrl
};

const getters = {
    remitaAdvisoryCallBackUrl: () => remitaAdvisoryCallBackUrl,
    loadingBladeUrl: () => loadingBladeUrl,
    getLogoutUserUrl: () => logoutUserUrl,
    remitaAccreditationCallBackUrl: () => remitaAccreditationCallBackUrl,
    recaptchaKey: () => recaptchaKey,
    blankImageUrl: () => blankImageUrl,
    getResetPasswordUrl: () => resetPasswordUrl,
    schoolFolderUrl: state => state.rootUrl+'/storage/schools/school'+state.schoolId,
    getAuthorizationToken: state => state.authorizationToken,
    getRootUrl: () => rootUrl,
    websiteUrl: () => websiteUrl,
    printReceiptUrl: () => printReceiptUrl,
    remitaApiKey: state => state.remitaApiKey,
    remitaPaymentUrl: state => state.remitaPaymentUrl,
    remitaMerchantId: state => state.remitaMerchantId,
    completeImageUrl: () => completeImageUrl,
    certificateImageUrl: () => certificateImageUrl,
    getupdatePasswordUrl: () => updatePasswordUrl,
    getportalUrl: () => portalUrl,
    getExportIndexingListUrl: () => exportIndexingListUrl
};

const actions = { };

const mutations = { };

export default {
    state,
    getters,
    actions,
    mutations
}